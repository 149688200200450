.puzzles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.board-container {
  width: 100%;
  max-width: 750px; /* Limit the size of the board */
  margin-bottom: 20px;
}

.controls-container {
  width: 100%;
  max-width: 500px; /* Match the width of the board */
  display: flex;
  justify-content: space-around;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 20px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: #000;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.grid-item:hover {
  background-color: #e0e0e0;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Styling for the disabled "Complete" button */
button.end:disabled {
  background-color: red;
  cursor: not-allowed; /* Changes the cursor to indicate the button is not clickable */
}
